import { FC, useContext, useEffect, useRef, useState } from "react";
import user_photo from "./img/user_photo.png";
import app_setting_logo from "../../sat-landing/img/solar_qr-code-line-duotone.svg";
import { withNamespaces } from "react-i18next";
import { twJoin } from "tailwind-merge";
import BasicInfoSection from "./BasicInfoSection";
import NoticeBoard from "./NoticeBoard";
import { UserDataContext } from "../../userProfile";
import { ProfileUploadIcon } from "../common/Icons";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import CommonErrorPopup from "../common/CommonErrorPopup";
import styles from "./UserInfo.module.scss";
import { NavLink } from "react-router-dom";
import { ProfileContext } from "../UserProfile/UserProfile";
import { Camera, CameraResultType } from '@capacitor/camera';
interface IUserInfo {
  loginData: any;
  props: any;
}
const profile_routes = [
  {
    label: "basic_info",
    link: "/profile",
  },
  {
    label: "notice_board",
    link: "/profile/notice_board",
  },
];
export const image_type = [
  "svg+xml",
  "png",
  "jpeg",
  "webp",
  "gif",
  "tiff",
];
const UserInfo = (props: any) => {
  const { activeTab, t, showDot } = props;
  const data = useContext(UserDataContext);
  const profileContext: any = useContext(ProfileContext);
  const [profileData, setProfile] = useState<any>(
    profileContext?.profileData?.employee
  );
  const popupSatatus = profileContext.profileData?.popupSatatus;
  const loginData: any = data;
  const [profileImage, setProfileImage] = useState<string>(
    loginData?.user?.employee?.photo
  );
  const profileImageRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const changeProfileImage = () => {
    profileImageRef.current?.click();
  };
  const takePicture = async () => {
    console.log("take picture")
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri
    }).then(async (result) => {
      setProfileImage(result.webPath ?? '');
      document.getElementById("profileImage")?.setAttribute("src", result.webPath ?? '');
      if (image_type.includes(result.format)) {
        if (result.webPath) {
          const response = await fetch(result.webPath)?.then((res) => {
            const blob = res.blob();
            blob.then((re) => {
              console.log("blob success ", JSON.stringify(re))
              uploadFileFunc(re);
            })

          });

        }
      } else {
        setErrorMsg("The uploaded file should be image type.");
      }
    }).catch((error)=>{
      console.log("error ",error);
    })

  };
  const changeProfile = (e: any) => {
    var filesize = Math.round(e.target.files[0]?.size / 1000) / 1024;
    if (filesize > 2) {
      setErrorMsg("The uploaded image size is greater than 2mb. ");
      return;
    }
    var url = URL.createObjectURL(e.target.files[0]);
    setProfileImage(url);
    document.getElementById("profileImage")?.setAttribute("src", url);
    if (image_type.includes(e.target.files[0].type)) {
      uploadFileFunc(e.target.files[0]);
    } else {
      setErrorMsg("The uploaded file should be image type.");
    }
  };
  const uploadFileFunc = async (file: any) => {
    if (file) {
      let formSaveData = new FormData();
      formSaveData.append("file", file);
      await axios(endpointUrl + "profile/upload_profile", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          setProfile(res?.data?.employee);
          setProfileImage(res?.data?.employee?.photo);
          profileContext.changeProfileData(res?.data);
          console.log("upload success ",JSON.stringify(res?.data))
        })
        .catch((err) => {
          console.log("upload error ",JSON.stringify(err))
          setErrorMsg(err?.response?.data.message);
        });
    }
  };
  useEffect(() => {
    setProfile(profileContext?.profileData?.employee);
    setProfileImage(profileContext?.profileData?.employee?.photo);
  }, [profileContext]);
  return (
    <div className=" px-5">
      <div className="text-left">
        <div className="flex items-center">
          <div className="w-full">
            <div className="w-full flex items-center justify-between">
              <div className="relative max-w-[250px] 2xl:max-w-[160px]">
                <div className="flex items-center relative">
                  <div className="relative mr-[15px] max-h-[80px] rounded-[20px]">
                    <div
                      className={twJoin(
                        styles.profileImageDiv,
                        "bg-[#A5A6F6] w-[80px] h-[80px] inline-block rounded-[20px] overflow-hidden relative"
                      )}
                    >
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="profile"
                          className="object-cover rounded-lg aspect-[80/80]"
                        />
                      ) : (
                        <button className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase py-[5px] px-[14px] font-poppins font-medium md:text-[32px] text-[28px]">
                          {profileData?.name
                            ? profileData?.name.slice(0, 1)
                            : profileData?.name?.slice(0, 1)}
                        </button>
                      )}
                      <div
                        // onClick={() => changeProfileImage()}
                        className={twJoin(
                          styles.customUploadBtn,
                          "hidden rounded-[20px] w-full h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                        )}
                      >
                        <ProfileUploadIcon
                          color="#fff"
                          width={126}
                          height={126}
                        />
                        <button
                          onClick={(e) => takePicture()}
                          className="opacity-0 top-0 left-0 w-full h-full absolute cursor-pointer"
                        // type="file"
                        // ref={profileImageRef}
                        // accept="image/*"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold text-body-23 text-sat_darkblackv2 leading-[normal] max-w-[150px] break-words">
                      {profileData?.name}
                    </p>
                    <p className="font-normal heading3 text-[#28282850]">
                      {profileData?.employeeID}
                    </p>
                  </div>
                </div>
              </div>
              <NavLink to={{ pathname: "/profile/qr" }}>
                <img src={app_setting_logo} alt="app_setting_logo" />
              </NavLink>
            </div>
            <p className="font-normal text-sat_darkblackv2 heading3 mt-4">
              {profileData?.jobTitle}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6 mb-7">
        <div className="grid grid-cols-2 gap-5">
          {profile_routes?.map((rout, key) => {
            return (
              <NavLink
                key={key}
                to={{
                  pathname: rout.link,
                }}
                onClick={() => {
                  const scrollbarWidth =
                    window.innerWidth - document.documentElement.clientWidth;
                  document.documentElement.style.setProperty(
                    "--scrollbar-width",
                    `${scrollbarWidth}px`
                  );
                }}
                id={rout.label}
                className={twJoin(
                  activeTab == rout.label
                    ? "border-b-2 border-b-sat_primary font-bold text-sat_darkblackv2"
                    : "border-b-2 border-b-transparent opacity-50 font-normal text-sat_darkblackv2",
                  "text-body-md w-full p-3 relative",

                )}
              >
                <span className={twJoin((rout.label == 'notice_board' && showDot == true) ? 'before:block before:content-normal before:absolute before:right-[-12px] before:w-[6px] before:h-[6px] before:bg-sat_primary before:rounded-full before:top-[40%] before:-translate-y-1/2' : '', "inline-block relative")}>{t(rout.label)}</span>
              </NavLink>
            );
          })}
        </div>
        <div>{props.children}</div>
      </div>
      <CommonErrorPopup
        show={errorMsg != "" ? true : false}
        setShow={() => setErrorMsg("")}
        message={errorMsg}
      />
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(UserInfo);
